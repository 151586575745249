import React from 'react';
import Helmet from '../project/Helmet';
import {t} from '@lingui/macro';
import FlagAdd from './flags/FlagAdd';
import FlagList from './flags/FlagList';

/**
 * @dusan
 */

class FlagsPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Príznaky produktov`}
            />
            <div className="full-size-height d-flex flex-column">
                <div className="pt-2 add-max-width flex-item-static">
                    <FlagAdd/>
                </div>
                <div className="m-3 overflow-auto flex-item-dynamic-1">
                    <FlagList/>
                </div>
            </div>
        </React.Fragment>;
    }

}

export default FlagsPage;