import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import Tooltip from '../../general/Tooltip';
import {FlagType} from '../../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import FlagForm from './FlagForm';

/**
 * @dusan
 */

class FlagEdit extends React.PureComponent {
    static propTypes = {
        flag: FlagType.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const {flag} = this.props;
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Úprava príznaku</Trans>}>
                    <Button className="mx-1" icon="edit" size="small"/>
                </Tooltip>
            }
            values={{isEdit: true, flag: flag}}
            onFinishSuccessful={() => {reloadData([GLOBAL_DATA.FLAGS])}}
            title={<Trans>Úprava príznaku</Trans>}
            Form={FlagForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť príznak`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(FlagEdit);