import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import FlagForm from './FlagForm';

/**
 * @dusan
 */

class FlagAdd extends React.PureComponent {
    static propTypes = {
        buttonType: PropTypes.string,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    }.isRequired;

    static defaultProps = {
        buttonType: "primary"
    };

    render() {
        const {buttonType, [GLOBAL_DATA.RELOAD_DATA]: reloadData} = this.props;
        return <FormModal
            openNode={
                <Button className="full-size-width" type={buttonType} icon="plus">
                    <span className="ml-2"><Trans>Nový príznak</Trans></span>
                </Button>
            }
            values={{isEdit: false}}
            onFinishSuccessful={() => {reloadData([GLOBAL_DATA.FLAGS])}}
            title={<Trans>Nový príznak</Trans>}
            Form={FlagForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa pridať príznak`)}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(FlagAdd);